<template>
  <div>

    <div class="context">
      <!--      <div class="rainbow">-->
      <!--        🧧排行榜激励活动红包派送中..请查看我们的社区<a style="color:red"-->
      <!--                                                      href="http://bbs.l4d2love.com/d/2-l4d2lovepai-xing-bang-ji-li-huo-dong">#红包活动#</a>-->
      <!--      </div>-->
      <q-banner v-show="null != user.cacheReady && user.cacheReady > 0" inline-actions class="text-white bg-orange">
        您在上局匹配中未准备,下次仍未准备您将面临封禁!
      </q-banner>
      <!--      <q-banner v-show="!socketConnect" inline-actions class="text-white bg-orange">-->
      <!--        您在上局匹配中未准备,下次仍未准备您将面临封禁!-->
      <!--      </q-banner>-->
      <q-banner v-show="!socketConnect" inline-actions class="text-white bg-red" style="text-align: center">
        <q-spinner-grid color="white" size="sm" style="margin-right: 10px" />
        与服务器的连接已中断,正在尝试重新连接...
      </q-banner>
      <div>
        <div class="row indexMain">
          <!--  在线用户  -->
          <div class="col-12 col-md-3">
            <div class="q-ma-md">
              <q-bar
                style="background-image: linear-gradient(to right, #0aa0fe 0%, #495aff 100%);color:white;border-radius: 15px">
                <q-icon name="group" />
                <div class="col text-center">
                  在线玩家
                </div>
              </q-bar>
              <!-- <div class="text-h6">玩家</div> -->
              <!-- 正在匹配的玩家会优先排序 -->
              <q-input v-model="searchText" filled outlined label="搜索玩家" dense class="q-mt-md">
                <template v-slot:prepend>
                  <q-icon name="search" />
                </template>
                <template v-slot:append>
                  <!-- Render the clear button only when there is input -->
                  <q-icon v-if="searchText" name="clear" class="cursor-pointer" @click="clearSearchText" />
                </template>
              </q-input>

              <q-list style="height: 76vh">
                <q-item-label header>在线:[<span class="text-green" style="font-weight: bold;">{{ onlinePlayer.length
                }}</span>]
                  游戏中:[<span class="text-orange" style="font-weight: bold;">{{ inGamePlayer }}</span>]
                  匹配中:[<span class="text-blue" style="font-weight: bold;">{{ inQueuePlayer }}</span>]
                </q-item-label>
                <q-scroll-area ref="scrollAreaRef" style="height: 70vh;padding: 10px 10px">
                  <!-- <q-scroll-area style="height: 630px;"> -->
                  <q-item :style="{ background: null != u.cardBg ? 'url(' + u.cardBg + ')' : '' }" style="
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-position: center;
                      " class="q-mb-sm" v-for="u in filteredOnlinePlayers" :key="u.steamId" clickable v-ripple>
                    <q-item-section avatar>
                      <q-avatar rounded size="40px">
                        <img :src="u.avatarFull">
                        <q-badge v-if="null != u.inGame && u.inGame" color="orange" rounded floating />
                        <q-badge v-else color="green" rounded floating />
                      </q-avatar>
                    </q-item-section>

                    <q-item-section>
                      <q-item-label style="font-size: 16px;">{{
                        u.personaName
                      }}
                      </q-item-label>
                      <q-item-label caption>
                        游戏总时长 {{ Math.round(u.playTime / 60) }}小时
                      </q-item-label>
                    </q-item-section>

                    <q-item-section side style="width: 100px;" v-if="u.rankScore != -1">
                      <q-img v-if="null != u && u.isRank >= 7" :src="u.level.levelImage" spinner-color="white" fit="fill"
                        style="height: 45px; max-width: 40px;" />
                    </q-item-section>

                    <q-tooltip transition-show="scale" transition-hide="scale" anchor="center right" self="center right">
                      {{ u.personaName }}
                    </q-tooltip>

                    <q-menu touch-position context-menu>

                      <q-list dense style="min-width: 100px">
                        <q-item clickable v-close-popup>
                          <q-item-section>{{ u.personaName }}</q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup :href="'https://steamcommunity.com/profiles/' + u.steamId"
                          target="_blank">
                          <q-item-section>{{ u.steamId }}</q-item-section>
                        </q-item>
                        <q-separator spaced />
                        <!-- <q-item clickable v-close-popup :href="'https://steamcommunity.com/profiles/' + u.steamId" target="_blank">
                            <q-item-section>查看Steam资料</q-item-section>
                          </q-item> -->
                        <q-item clickable v-close-popup>
                          <q-item-section @click="invitePlayer(u)">邀请组队</q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup>
                          <q-item-section>查看资料</q-item-section>
                        </q-item>
                        <!-- <q-item clickable v-close-popup>
                            <q-item-section>添加好友</q-item-section>
                          </q-item> -->
                        <q-separator />
                      </q-list>

                    </q-menu>

                  </q-item>
                </q-scroll-area>
              </q-list>
              <!-- <q-tabs
                v-model="onlineTab"
                no-caps
                inline-label
                class="text-text-orange-12"
            >
              <q-tab name="all" icon="lobby" label="大厅">
              </q-tab>
              <q-tab name="friend" icon="account" label="朋友">
              </q-tab>
            </q-tabs> -->

              <!-- <q-tab-panels v-model="onlineTab" animated style="background: none"> -->
              <!-- <q-tab-panel name="all" style="padding: 0px"> -->

              <!-- </q-tab-panel> -->
              <!--              <q-tab-panel name="queue" style="padding: 0px">-->
              <!--                <div style="padding: 16px">-->
              <!--                  <div class="text-h6">匹配队列中的玩家...</div>-->
              <!--                </div>-->
              <!--                <q-list>-->
              <!--                  <q-scroll-area style="height: 630px;">-->
              <!--                    <q-item class="q-mb-sm" v-for="u in inQueuePlayer" :key="u.steamId" clickable v-ripple>-->
              <!--                      <q-item-section avatar>-->
              <!--                        <q-avatar rounded size="50px">-->
              <!--                          <img :src="u.avatarFull">-->
              <!--                        </q-avatar>-->
              <!--                      </q-item-section>-->

              <!--                      <q-item-section>-->
              <!--                        <q-item-label style="font-size: 20px">-->
              <!--                          {{ u.personaName }}-->
              <!--                        </q-item-label>-->
              <!--                        <q-item-label caption lines="1">匹配中...-->
              <!--                        </q-item-label>-->
              <!--                      </q-item-section>-->

              <!--                      <q-item-section side>-->
              <!--                        <q-badge rounded color="green"/>-->
              <!--                      </q-item-section>-->
              <!--                      <q-tooltip transition-show="scale"-->
              <!--                                 transition-hide="scale" anchor="center right" self="center right">-->
              <!--                        {{ u.personaName }}-->
              <!--                      </q-tooltip>-->
              <!--                    </q-item>-->
              <!--                  </q-scroll-area>-->
              <!--                </q-list>-->
              <!--              </q-tab-panel>-->
              <!-- <q-tab-panel name="friend">
                <div class="text-h6">您的朋友</div>
                被你发现了,功能仍在开发!
              </q-tab-panel> -->
              <!-- </q-tab-panels> -->
            </div>
          </div>

          <!--  全服聊天  -->
          <div class="col-12 col-md-6">

            <div class="q-ma-md">
              <q-bar
                style="background-image: linear-gradient(to right, #0aa0fe 0%, #495aff 100%);color:white;border-radius: 15px">
                <q-icon name="email" />
                <div class="col text-center">
                  聊天大厅 - L4D2Love
                </div>
              </q-bar>
              <q-scroll-area ref="scrollAreaRef" style="height: 76vh;padding: 10px 10px">
                <div v-for="chat in chats" :key="chat.id">
                  <q-chat-message v-if="chat.type == 0" :text="[chat.content]" size="5"
                    :sent="(user.steamId == chat.steam_id)"
                    :text-color="$q.dark.isActive ? 'grey-4' : (user.steamId == chat.steam_id ? 'white' : 'black')"
                    :bg-color="$q.dark.isActive ? (user.steamId == chat.steam_id ? 'grey-10' : 'grey-8') : (user.steamId == chat.steam_id ? 'blue-7' : 'grey-4')">
                    <template v-slot:name>
                      <div class="text-h5 text-overline">{{ chat.persona_name }}
                        <q-badge v-if="chat.rank_score != -1" outline color="primary" :label="chat.levelName" />
                      </div>
                    </template>
                    <template v-slot:stamp style="font-size: 12px">{{
                      timestampToTime(chat.create_time / 1000)
                    }}
                    </template>
                    <template v-slot:avatar>
                      <img class="q-message-avatar q-message-avatar--sent" :src="chat.avatar_full">
                    </template>
                  </q-chat-message>
                  <!--  游戏开始广播  -->
                  <div class="q-pa-md q-gutter-sm" v-if="chat.type == 1">
                    <q-card flat bordered style="padding: 10px 5px;border:2px solid #089eee;">
                      <q-card-section horizontal>
                        <q-card-section class="col-9 q-pt-xs">
                          <div class="">有一场比赛开始了,你可以加入观看 {{ chat.content.gameId }}</div>
                          <div class="text-h3 text-overline text-primary">
                            [{{ chat.content.map.state == 0 ? '官方' : '第三方' }}]{{ chat.content.mixmap == 0 ?
                              chat.content.map.mapName : '混合地图 MixMap' }}
                          </div>
                          <div class="text-h4 text-overline text-orange-9">{{
                            chat.content.server_info.serverName
                          }}
                          </div>
                          <div class="q-mt-sm q-mb-xs">参赛人员:</div>
                          <div class="text-caption text-grey">
                            <div class="q-pa-md q-gutter-sm">
                              <q-avatar v-for="p in chat.content.survivor_team" :key="p.steamId" size="lg" rounded>
                                <img :src="p.avatarFull">
                                <q-tooltip anchor="top middle" self="top middle">
                                  {{ p.personaName }}
                                </q-tooltip>
                              </q-avatar>
                              <q-avatar>
                                <div>VS</div>
                              </q-avatar>
                              <q-avatar v-for="p in chat.content.infected_team" :key="p.steamId" size="lg" rounded>
                                <img :src="p.avatarFull">
                                <q-tooltip anchor="top middle" self="top middle">
                                  {{ p.personaName }}
                                </q-tooltip>
                              </q-avatar>
                            </div>
                          </div>
                        </q-card-section>

                        <q-card-section class="col-3 flex flex-center">
                          <q-img class="rounded-borders"
                            :src="chat.content.mixmap == 0 ? chat.content.map.mapImage : 'https://s11.ax1x.com/2024/02/19/pFYi736.jpg'" />
                        </q-card-section>
                      </q-card-section>

                      <q-separator />

                      <q-card-actions>
                        <q-btn @click="watchGame(chat.content.server_info)" color="primary">
                          加入观战
                        </q-btn>
                        <span style="margin-left: 10px">注意:观战玩家发言如影响对局玩家,请截图举报至社区</span>
                      </q-card-actions>
                    </q-card>
                  </div>
                  <!--  重要通知  -->
                  <q-banner inline-actions v-if="chat.type == 2" class="text-white bg-red">
                    {{ chat.content.msg }}
                  </q-banner>
                </div>


              </q-scroll-area>

              <q-form @submit="sendChatHandle" class="q-gutter-md">
                <q-input style="margin-top: 20px" bottom-slots v-model="sendChatMessage"
                  label="禁止公屏侮辱谩骂,发现第一次30分钟警告,第二次7天,多次违反永久封禁" maxlength="52">
                  <template v-slot:before>
                    <q-avatar style="border-radius: 5px" square size="45px">
                      <img :src="user.avatarFull">
                    </q-avatar>
                  </template>

                  <template v-slot:append>
                    <q-icon v-if="sendChatMessage !== ''" name="close" @click="sendChatMessage = ''"
                      class="cursor-pointer" />
                    <!--                    <q-icon name="schedule"/>-->
                  </template>

                  <template v-slot:after>
                    <q-btn type="submit" color="primary" flat @click="sendChatHandle" icon="send" />
                  </template>
                </q-input>

              </q-form>
            </div>
          </div>

          <!--  用户面板  -->
          <div class="col-12 col-md-3">
            <div class="q-ma-md" style="">
              <q-bar
                style="background-image: linear-gradient(to right, #0aa0fe 0%, #495aff 100%);color:white;border-radius: 15px">
                <q-icon name="person" />
                <div class="col text-center">
                  个人面板
                </div>
              </q-bar>
              <q-list style="position: relative;height: 76vh" padding>
                <q-item>
                  <q-item-section avatar>
                    <q-avatar rounded size="60px">
                      <img :src="user.avatarFull">
                    </q-avatar>
                  </q-item-section>

                  <q-item-section avatar v-if="null != user && user.isRank >= 7">
                    <q-avatar rounded size="60px">
                      <img :src="user.level.levelImage" style="height: 55px; max-width: 50px">
                    </q-avatar>
                  </q-item-section>


                  <q-item-section>
                    <q-item-label class="text-orange-12" style="font-size: 22px">{{ user.personaName }}</q-item-label>
                    <q-item-label v-if="null != user && user.isRank >= 7" caption>
                      {{ user.level.levelName }}
                    </q-item-label>
                    <q-item-label caption>
                      游戏总时长 {{ Math.round(user.playTime / 60) }}小时
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-separator spaced />
                <q-scroll-area ref="scrollAreaRef" style="height: 76vh;padding: 10px 10px">
                  <q-item-label header>荣誉:</q-item-label>
                  <q-item>
                    <q-item-section>
                      <q-item-label caption lines="1" style="font-size: 14px">
                        获赞:<span class="text-orange-12">0</span>
                        被踩:<span class="text-orange-12">0</span>
                        动态卡片:<span class="text-orange-12">0</span>
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-separator spaced />
                  <q-item-label header>排位信息:</q-item-label>
                  <q-item v-if="user.isRank >= 7">
                    <q-item-section>
                      <q-item-label caption lines="1" style="font-size: 14px">
                        排位分:<span class="text-orange-12">{{ user.rankScore }}</span>
                        排位场次:<span class="text-orange-12">{{ user.isRank }}</span>
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item v-if="user.isRank < 7 && user.rankScore != -1">
                    请先完成定级赛: <span style="margin-left: 15px" class="text-orange-12">{{ user.isRank }} / 7</span>
                  </q-item>
                  <q-item v-if="user.rankScore == -1">
                    等待校准,请点击开始匹配
                  </q-item>

                  <q-separator spaced />
                  <q-item-label header>队伍 平均分:[
                    <span class="text-orange-12">
                      {{ room.avgRank }}
                      <q-tooltip transition-show="flip-right" transition-hide="flip-left" class="bg-purple text-body2"
                        :offset="[10, 10]">
                        队伍平均分也是匹配分
                      </q-tooltip>
                    </span>
                    ]

                    高分差队列:
                    [
                    <span class="text-orange-12">
                      {{ room.extendQueue ? '允许' : '拒绝' }}
                      <q-tooltip transition-show="flip-right" transition-hide="flip-left" class="bg-purple text-body2"
                        :offset="[10, 10]">
                        玩家游戏时长 > 500 允许进入高分差队列
                      </q-tooltip>
                    </span>
                    ]
                  </q-item-label>
                  <q-item v-ripple>
                    <q-item-section v-for="roomPlayer in room.players" :key="roomPlayer.steamId" avatar>
                      <q-avatar rounded size="50px">
                        <img :src="roomPlayer.avatarFull">
                        <q-badge v-if="room.ownerPlayer.steamId == roomPlayer.steamId" floating color="teal">房主
                        </q-badge>
                      </q-avatar>
                      <q-menu touch-position context-menu>
                        <q-list dense style="min-width: 100px">
                          <q-item clickable v-close-popup>
                            <q-item-section>踢出队伍</q-item-section>
                          </q-item>
                          <q-separator />
                        </q-list>
                      </q-menu>
                      <q-tooltip transition-show="flip-right" transition-hide="flip-left" class="bg-purple text-body2"
                        :offset="[10, 10]">
                        {{ roomPlayer.personaName }}
                      </q-tooltip>
                    </q-item-section>

                    <q-item-section @click="quitRoom" avatar>
                      <q-avatar rounded size="48px">
                        <img src="@/assets/icon/close.png">
                      </q-avatar>
                      <q-tooltip class="bg-red" :offset="[10, 10]">
                        退出队伍
                      </q-tooltip>
                    </q-item-section>
                  </q-item>

                  <q-separator spaced />
                  <q-item-label header>匹配设置</q-item-label>

                  <!-- <q-item tag="label" v-ripple>
                    <q-item-section>
                      <q-item-label>接受第三方地图</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle @click="switchOtherMap" color="blue" v-model="room.otherMap" val="battery" />
                    </q-item-section>
                  </q-item> -->

                  <q-item tag="label" v-ripple>
                    <q-item-section>
                      <q-item-label>开启 Mixmap</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle @click="switchMixmap" color="blue" v-model="room.mixMap" val="battery" />
                    </q-item-section>
                  </q-item>

                  <!-- <q-separator spaced /> -->
                  <!-- <q-item-label header>消息通知音量</q-item-label> -->

                  <q-item>
                    <q-item-section side>
                      <q-icon color="primary" name="volume_down" />
                    </q-item-section>
                    <q-item-section>
                      <q-slider v-model="notifyAudioVolume" @change="changeAudioVolume" :min="0" :max="10" label
                        color="primary" />
                    </q-item-section>
                    <q-item-section side>
                      <q-icon color="primary" name="volume_up" />
                    </q-item-section>
                  </q-item>
                </q-scroll-area>


                <!-- <q-item style="margin-top: 20px" bottom-slots> -->
                <q-form class="q-gutter-md">
                  <q-item style="position: absolute;bottom: 0px;width: 100%">
                    <q-item-section>
                      <q-btn :disable="user.inGame" class=" blob queue full-width"
                        :style="!room.inQueue ? 'background-image: linear-gradient(to right, #0aa0fe 0%, #495aff 100%);color:white' : 'background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);color:white'"
                        @click="switchQueue">
                        <div class="row items-center no-wrap">
                          <div class="text-center">
                            {{ !room.inQueue ? '开始匹配' : '取消匹配' }}
                          </div>
                        </div>
                      </q-btn>
                    </q-item-section>
                  </q-item>
                </q-form>

              </q-list>
            </div>
          </div>

          <!--  匹配按钮  -->
          <q-page-sticky position="bottom-right" :offset="[28, 20]">

          </q-page-sticky>

          <q-inner-loading :showing="initVisiable" style="height: 100vh">
            <q-spinner-grid size="30px" color="primary" />
          </q-inner-loading>
        </div>
      </div>
    </div>

    <!-- 特效 -->
    <div class="area">
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>

    <!--   队列模态框   -->
    <q-dialog v-model="room.inQueue" seamless position="top">
      <q-card style="width: 100%">
        <!--          <q-linear-progress stripe query :value="0.6" color="pink"/>-->

        <q-card-section class="row items-center no-wrap">
          <!--          <q-circular-progress-->
          <!--              indeterminate-->
          <!--              size="45px"-->
          <!--              :thickness="0.6"-->
          <!--              color="green"-->
          <!--              center-color="grey-8"-->
          <!--              class="q-ma-md"-->
          <!--          />-->
          <q-spinner-pie color="primary" size="3em" style="margin-right: 20px" />
          <div>
            <div class="text-primary" style="font-size: 16px">正在寻找对局中...</div>
            <div class="text-grey" style="font-size: 14px">
              进度条结束时您可能会被分配至
              <span class="text-orange">
                高分差对局
                <q-tooltip class="bg-amber text-black shadow-4" :offset="[10, 10]">
                  为了避免您等待过久,我们会在进度条结束后扩大匹配范围,如果你认为这会对您造成影响,请随时取消匹配
                </q-tooltip>
              </span>
            </div>
            <div class="text-grey">已用时:{{ queueStartTime }}</div>
          </div>

          <q-space />

          <q-btn flat round icon="close" @click="switchQueue" v-close-popup />
        </q-card-section>
        <q-linear-progress size="10px" stripe rounded :value="queueWaitTime" color="green" class="q-mt-sm" />
      </q-card>
    </q-dialog>

    <!--   readyup确定模态框   -->
    <q-dialog v-model="queueConfirmDialog" persistent transition-show="scale" transition-hide="scale">
      <div class="bg-custom-color blob green text-center" style="max-width: 60%; min-width: 60%;">
        <q-bar class="" style="color: white;background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%);">
          <!-- <q-icon name="ready" /> -->
          <div class="col text-center text-weight-bold unselectable-text">
            你的对局已就绪
          </div>
        </q-bar>
        <q-banner class="bg-custom-color text-center">
          <span class="text-weight-bold text-h5 unselectable-text text-white">Zonemod 4V4</span>
        </q-banner>
        <q-btn size="lg" color="green" @click="readyUp" v-show="!user.isConfirmReadyUp" :outline="false" label="准备就绪" />
        
        <div class="row" style="display: flex; justify-content: center;">
          <div class="bg-custom-color" v-for="player in queueConfirmPlayers"
            :key="player.steamId" style="padding: 10px 10px">
            <!-- 匹配玩家头像展示 -->
            <q-item clickable v-ripple v-show="user.isConfirmReadyUp">
              <q-item-section>
                <q-avatar rounded size="48px" :class="player.isConfirmReadyUp ? 'glowing-ready' : '' ">
                  <img
                    :src="room.players.some(roomPlayer => roomPlayer.steamId === player.steamId) ? room.players.find(roomPlayer => roomPlayer.steamId === player.steamId).avatarFull : require('@/assets/avatar_unknow.jpg')" />
                </q-avatar>
              </q-item-section>
            </q-item>
          </div>
        </div>
      </div>
    </q-dialog>

    <!--   游戏开始   -->
    <q-dialog v-model="user.inGame" persistent full-width transition-show="slide-up" transition-hide="slide-down">
      <q-layout view="lHr Lpr lFr" container :class="$q.dark.isActive ? 'bg-custom-color' : 'bg-white'">
        <q-header class="bg-primary">
          <q-bar class="q-electron-drag" style="background-image: linear-gradient(to right, #0aa0fe 0%, #495aff 100%);">
            <div>游戏已开始请在六分钟内连接:</div>
            <q-space />
            <q-btn flat v-close-popup round dense icon="close" />
          </q-bar>
        </q-header>

        <q-page-container>
          <q-page>
            <div class="row" style="padding: 0% 1%">
              <div class="col-12 col-md-3">
                <div class="q-pa-md row items-start q-gutter-md">
                  <q-card style="box-shadow: none;width: 80%">
                    <q-img
                      :src="game.mixmap == 0 ? game.gameMap.mapImage : 'https://s11.ax1x.com/2024/02/19/pFYi736.jpg'" />

                    <q-card-section>
                      <div class="text-h5  text-orange-9">{{ game.mixmap == 0 ? game.gameMap.mapName : '混合地图 MixMap' }}
                      </div>
                      <div class="text-caption text-grey">
                        L4D2Love 竞赛匹配平台
                      </div>
                    </q-card-section>

                    <q-card-actions>
                      <div class="text-grey">
                        对局ID:
                      </div>
                      <q-space />
                      <div class="text-grey">
                        {{ null != queueGameId ? queueGameId : game.gameServer.gameId }}
                      </div>
                    </q-card-actions>

                    <q-card-actions>
                      <div class="text-grey">
                        服务器地址
                      </div>
                      <q-space />
                      <div class="text-grey">
                        {{ game.gameServer.serverIp }}
                      </div>
                    </q-card-actions>

                    <q-card-actions>
                      <div class="text-grey">
                        服务器提供者
                      </div>
                      <q-space />
                      <div class="text-grey">
                        {{ game.gameServer.serverSource == 0 ? '官方' : '第三方' }}
                      </div>
                    </q-card-actions>

                    <q-card-actions>
                      <div class="text-grey">
                        描述
                      </div>
                      <q-space />
                      <div class="text-grey">
                        {{ game.gameServer.serverDescription }}
                      </div>
                    </q-card-actions>

                    <q-card-actions>
                      <div class="text-grey">
                        服务器名称
                      </div>
                      <q-space />
                      <div class="text-grey">
                        {{ game.gameServer.serverName }}
                      </div>
                    </q-card-actions>

                    <q-card-actions>
                      <div class="text-grey">
                        最大支持玩家
                      </div>
                      <q-space />
                      <div class="text-grey">
                        {{ game.gameServer.maxPlayers }}
                      </div>
                    </q-card-actions>

                    <q-card-actions>
                      <div class="text-grey">
                        服务器版本
                      </div>
                      <q-space />
                      <div class="text-grey">
                        {{ game.gameServer.version }}
                      </div>
                    </q-card-actions>

                    <q-card-actions>
                      <div class="text-grey">
                        Tags
                      </div>
                      <q-space />
                      <div class="text-grey">
                        {{ game.gameServer.edfTags }}
                      </div>
                    </q-card-actions>

                    <q-slide-transition>
                      <div v-show="expanded">
                        <q-separator />
                        <q-card-section class="text-subitle2">
                          {{ game.gameMap.mapCode }}
                        </q-card-section>
                      </div>
                    </q-slide-transition>

                    <q-card-actions>
                      <q-btn @click="connectGameServer" size="md" class="btn-fixed-width full-width text-white"
                        style="background-image: linear-gradient(to right, #868f96 0%, #596164 100%);" label="连接服务器" />
                      <q-space />
                    </q-card-actions>
                  </q-card>
                </div>
              </div>

              <div class="col-12 col-md-8">
                <!-- 说明 -->
                <div class="row" style="margin:4% 0%">
                  <q-card style="box-shadow: none;width: 100%;font-size: 16px">

                    <q-card-section>
                      <div class="text-grey">
                        <div class="text-h6  text-orange-9">游戏说明:</div>
                        <q-space />
                      </div>
                    </q-card-section>

                    <div style="padding: 0% 1%">
                      <q-card-actions>
                        <div class="text-grey">
                          <q-icon name="star" color="yellow" style="margin-left: 10px" />
                          完成这些地图 <span class="text-green"> {{ game.mixmap == 0 ? game.gameMap.mapCode : game.mixmapValue
                          }}</span> 后游戏自动结束
                        </div>
                      </q-card-actions>

                      <q-card-actions>
                        <div class="text-grey">
                          <q-icon name="star" color="yellow" style="margin-left: 10px" />
                          严禁<span class="text-red-5">中途退出，压力队友，辱骂，消极游戏</span>你会面临严重的惩罚!
                        </div>
                      </q-card-actions>

                      <q-card-actions>
                        <div class="text-grey">
                          <q-icon name="star" color="yellow" style="margin-left: 10px" />
                          你有<span class="text-red-5">6分钟</span>的时间加入游戏,否则游戏结束,未加入的玩家会被封禁
                        </div>
                      </q-card-actions>

                      <q-card-actions>
                        <div class="text-grey">
                          <q-icon name="star" color="yellow" style="margin-left: 10px" />
                          比赛结束存在奖惩机制,游戏中的表现会影响您的排位分,这会影响您匹配到的玩家质量
                        </div>
                      </q-card-actions>

                      <q-card-actions>
                        <div class="text-grey">
                          <q-icon name="star" color="yellow" style="margin-left: 10px" />
                          如在对局中遇到任何问题请记录游戏ID(查看左下角或游戏中!game)并投诉至社区
                        </div>
                      </q-card-actions>

                      <q-card-actions>
                        <div class="text-grey">
                          <q-icon name="star" color="yellow" style="margin-left: 10px" />
                          你可以点击右上角 X 关闭当前窗口,游戏未结束前刷新页面自动弹出当前窗口
                        </div>
                      </q-card-actions>
                    </div>

                  </q-card>
                </div>
                <!-- 阵容布局 -->
                <div class="row">
                  <div class="col-12 col-md-6">
                    <q-card style="box-shadow: none;width: 95%;">
                      <q-card-section>
                        <q-banner
                          style="border-radius: 10px;background-image: linear-gradient(to right, #0aa0fe 0%, #495aff 100%); text-align: center;"
                          class="text-white">
                          排位赛队伍 A
                        </q-banner>
                        <q-item :class="$q.dark.isActive ? 'bg-custom-color': 'bg-blue-grey-1'" style="margin: 5px;border-radius: 10px" clickable v-ripple
                          v-for="survivor in game.survivorTeam" :key="survivor.steamId">
                          <q-item-section side>
                            <q-avatar rounded size="48px">
                              <img :src="survivor.avatarFull" />
                            </q-avatar>
                          </q-item-section>
                          <q-item-section>
                            <q-item-label>{{ survivor.personaName }}</q-item-label>
                            <!--                              <q-item-label caption>{{ survivor.rankScore }}</q-item-label>-->
                          </q-item-section>
                          <q-item-section style="max-width: 60px;">
                            <q-img v-if="null != survivor && null != survivor.level && survivor.isRank >= 7"
                              :src="survivor.level.levelImage" spinner-color="white" fit="fill"
                              style="height: 55px; max-width: 50px" />
                            <q-badge v-if="null == survivor.level" outline color="red" label="定位赛" />
                          </q-item-section>
                        </q-item>
                      </q-card-section>
                    </q-card>
                  </div>
                  <div class="col-12 col-md-6">
                    <q-card style="box-shadow: none;width: 95%">
                      <q-card-section>
                        <q-banner
                          style="border-radius: 10px;background-image: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%); text-align: center;"
                          inline-actions class="text-white">
                          排位赛队伍 B
                        </q-banner>
                        <q-item :class="$q.dark.isActive ? 'bg-custom-color': 'bg-blue-grey-1'" style="margin: 5px;border-radius: 10px" clickable v-ripple
                          v-for="infected in game.infectedTeam" :key="infected.steamId">
                          <q-item-section side>
                            <q-avatar rounded size="48px">
                              <img :src="infected.avatarFull" />
                            </q-avatar>
                          </q-item-section>
                          <q-item-section>
                            <q-item-label>{{ infected.personaName }}</q-item-label>
                            <!--                            <q-item-label caption>{{ infected.rankScore }}</q-item-label>-->
                          </q-item-section>
                          <q-item-section style="max-width: 60px;">
                            <q-img v-if="null != infected && null != infected.level && infected.isRank >= 7"
                              :src="infected.level.levelImage" spinner-color="white" fit="fill"
                              style="height: 55px; max-width: 50px" />
                            <q-badge v-if="null == infected.level" outline color="red" label="定位赛" />
                          </q-item-section>
                        </q-item>
                      </q-card-section>
                    </q-card>
                  </div>
                </div>
              </div>
            </div>
          </q-page>
        </q-page-container>
      </q-layout>
    </q-dialog>

    <!--   封禁   -->
    <q-dialog v-model="user.isBan" persistent transition-show="scale" transition-hide="scale" position="standard">
      <q-card style="width: 100%">
        <q-bar class="q-electron-drag bg-negative text-white">
          <q-icon name="warning" />
          <div>你被 {{ banDialog.banBy }} 封禁了,解封之前你不能任何操作</div>
          <q-space />
        </q-bar>
        <q-card-section>
          <div style="font-size: 16px">
            <div class="text-grey">封禁原因: <span class="text-red">{{ banDialog.remark }}</span></div>
          </div>
        </q-card-section>
        <q-card-section>
          <div style="font-size: 16px">
            <div class="text-grey">预期的解封时间:<span class="text-red">{{ banDialog.toTime }}</span></div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <!--   数据校准   -->
    <q-dialog v-model="initModel" persistent transition-show="flip-down" transition-hide="flip-up">
      <q-card class="text-white"
        style="background-image: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%);width: 700px; max-width: 80vw;">
        <q-bar>
          <div>规则说明以及定位初试分</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip class="bg-white text-primary">关闭</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section>
          <div style="font-size: 18px">如果您是第一次使用平台,请务必阅读L4D2Love规则说明</div>
          <div style="font-size: 15px">⚠️点击这里查看我们的规则 <a target="_blank" style="color: white"
              href="http://bbs.l4d2love.com/d/6-l4d2lovepi-pei-ping-tai-wan-jia-shou-ze-v10">查看用户守则</a>
          </div>
          <div style="font-size: 15px">⚠️违反用户守则你会被封禁</div>
        </q-card-section>
        <q-separator color="white" inset />
        <q-card-section>
          <div style="font-size: 18px">为了您的游戏体验，我们需要获取您的游戏时长数据</div>
          <div style="font-size: 15px">⚠️注意您只需公开Steam隐私设置中的游戏详情和时长信息!</div>
          <div style="font-size: 15px">⚠️如果您已准备就绪，请勾选确认后点击校准数据</div>
          <div style="font-size: 15px">⚠️若仍提示无法获取Steam信息，请等待一段时间后刷新网页重试</div>
        </q-card-section>

        <q-card-section v-if="initRankDisable">
          <div style="font-size: 15px">目前您可能未公开Steam资料,请公开后先点击'更新Steam数据'按钮</div>
        </q-card-section>

        <q-card-section>
          <q-radio v-model="confirmInitScore" val="red" label="我确定我已经准备好了,并严格遵守用户守则!" color="red" />
        </q-card-section>

        <q-card-section align="right">
          <q-btn :loading="initModelLoading" outline @click="uploadSteamData" label="更新Steam数据" color="white">
            <template v-slot:loading>
              <q-spinner-hourglass class="on-left" />
              请稍后...
            </template>
          </q-btn>
          <q-btn :disable="initRankDisable" style="margin-left: 20px" outline @click="initRankScore" label="校准数据并定位"
            color="white" />
        </q-card-section>
      </q-card>
    </q-dialog>

    <!--   定位确认   -->
    <q-dialog v-model="rankModel" persistent transition-show="flip-down" transition-hide="flip-up">
      <q-card
        style="background-image: linear-gradient(to top, #a9866e 0%, #dac0a9 100%); min-width: 350px; max-width: 350px;"
        class="text-white">
        <q-bar style="background: #00000070 !important;">
          <div>🎉 定级赛完成</div>
          <q-space />
        </q-bar>

        <q-card-section horizontal>
          <q-card-section class="col-6 flex flex-center">
            <q-img :src="user.level.levelImage" spinner-color="white" fit="fill" style="max-width: 90px" />
          </q-card-section>
          <q-card-section class="q-pt-xs">
            <div class="text-overline" style="font-size: 13px">恭喜! 现已达到:</div>
            <div class="text-h6 q-mt-sm q-mb-xs" style="font-weight: bold;">{{ user.level.levelName }}</div>
            <div class="text-white" style="font-size: 16px">
              段位分 <span class="text-orange-11">{{ user.rankScore }}</span>
            </div>
          </q-card-section>
        </q-card-section>

        <q-separator />

        <q-card-section align="right">
          <q-btn class="full-width" outline @click="rankConfirmHandle" label="继续" color="white" v-close-popup>
          </q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>

    <!--   三方图   -->
    <q-dialog v-model="otherMapModel" persistent transition-show="flip-down" transition-hide="flip-up">
      <q-card class="bg-primary text-white">
        <q-bar>
          <div>⚠️开启三方图</div>
          <q-space />
          <!--          <q-btn dense flat icon="close" v-close-popup>-->
          <!--            <q-tooltip class="bg-white text-primary">关闭</q-tooltip>-->
          <!--          </q-btn>-->
        </q-bar>

        <q-card-section>
          <div class="text-h4 text-overline">开启条件:对局匹配后判断8个人全部开启三方图,则对局必然是第三方地图</div>
          <div class="text-h4 text-overline">请确保客户端中安装了以下地图(解压后放入addons):</div>
        </q-card-section>

        <q-card-section>
          <q-item style="margin-top: 5px" v-for="item in otherMapList" :key="item.id" tag="label"
            class="bg-white text-black" v-ripple>
            <q-item-section>
              <div>
                <q-img width="35px" :src="item.mapImage" />
              </div>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ item.mapName }}</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn @click="downloadOtherMap(item)" flat style="color: red" label="下载" />
            </q-item-section>
          </q-item>

          <q-card-section>
            <q-btn v-close-popup class="bg-white full-width text-black" label="我已知晓并下载安装" />
          </q-card-section>
        </q-card-section>


      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { notifyAudio } from "@/util/NotifyAudio"
import { ref } from "vue";
import { quequeBeep, setVolume, stopBeep } from "@/util/audioPlay";
import { Dialog, Loading, Notify, LoadingBar } from "quasar";
import { removeToken } from "@/util/auth";
import { uploadProfile, initRank, rankConfirm, otherMap } from "@/api/player";
import { loadUser } from "@/api/main";
import main from "@/main";

export default {
  name: "Index",
  data() {
    return {
      otherMapModel: false,
      otherMapList: [],
      socketConnect: true,
      rankModel: false,
      confirmInitScore: false,
      initModel: false,
      initModelLoading: false,
      onlineTab: ref('all'),
      tab: ref('mails'),
      user: {
        level: {
          levelImage: '',
        }
      },
      chats: [],
      sendChatMessage: '',
      gameDialog: false,
      queueGameId: '',
      selectMapId: ref(null),
      allSelectMapList: null,
      isSelectMap: false,
      queueConfirmMaps: [],
      queueConfirmPlayers: [],
      queueConfirmInterval: null,
      queueConfirmProgress: 60,
      notifyAudioVolume: 10,
      notifyAudioState: true,
      otherMap: false,
      queueStartTime: null,
      queueInterval: null,
      queueDialog: false,
      queueConfirmDialog: false,
      onlinePlayer: [],
      websocket: null,
      scrollAreaRef: ref(null),
      banDialog: {},
      queueWaitTime: 1.0,
      waitTime: 0,
      room: {},
      initVisiable: true,
      game: {
        gameServer: {},
        gameMap: {},
        mixmap: 0,
        mixmapValue: '',
        survivorTeam: [],
        infectedTeam: []
      },
      initRankDisable: false,
      inGamePlayer: 0,
      inQueuePlayer: 0,
      searchText: "",
    }
  },
  mounted() {
    this.init();
  },
  created() {
    // this.$q.dark.set(true)
    //this.init();
  },
  computed: {
    filteredOnlinePlayers() {
      return this.onlinePlayer.filter((u) =>
        u.personaName.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
  },
  methods: {
    init() {
      this.$options.sockets.onopen = ((r) => {
        this.socketConnect = true;
        setTimeout((s) => {
          let initMessage = { type: 9999 }
          this.$socket.send(JSON.stringify(initMessage));
        }, 300)
      })
      this.$options.sockets.onclose = ((res) => {
        this.socketConnect = false;
      })
      this.$options.sockets.onmessage = ((res) => {
        this.messageHandle(res)
      })
      otherMap().then((res) => {
        this.otherMapList = res.data.extend;
      })
    },
    clearSearchText() {
      // Clear the search text
      this.searchText = "";
    },
    rankConfirmHandle() {
      rankConfirm().then(() => {
        this.user.rankConfirm = 1;
        this.rankModel = false;
        location.reload();
      })
    },
    uploadSteamData() {
      this.initModelLoading = true;
      loadUser().then((res) => {
        let updatePlayer = res.data.extend;
        if (updatePlayer.playTime != 0) {
          //do something
          this.user.playTime = updatePlayer.playTime;
        }
        this.initRankDisable = false;
        this.initModelLoading = false;
      })
    },
    switchOtherMap() {
      let changeRoomMessage = {}
      changeRoomMessage.type = 2005;
      changeRoomMessage.room = {
        roomId: this.room.roomId,
        otherMap: this.room.otherMap,
        inQueue: this.room.inQueue,
      }
      if (this.room.otherMap) {
        this.otherMapModel = true;
      }
      this.$socket.send(JSON.stringify(changeRoomMessage));
    },
    switchMixmap() {
      let changeMixMapMessage = {}
      changeMixMapMessage.type = 2005;
      changeMixMapMessage.room = {
        roomId: this.room.roomId,
        otherMap: this.room.otherMap,
        mixMap: this.room.mixMap,
        inQueue: this.room.inQueue,
      }
      this.$socket.send(JSON.stringify(changeMixMapMessage));
    },
    switchQueue() {
      if (this.user.rankScore == -1) {
        this.initModel = true;
        if (this.user.playTime == 0) {
          this.initRankDisable = true;
        }
        return;
      }

      let changeRoomMessage = {}
      changeRoomMessage.type = 2005;
      changeRoomMessage.room = {
        roomId: this.room.roomId,
        otherMap: this.room.otherMap,
        mixMap: this.room.mixMap,
        inQueue: !this.room.inQueue,
      }
      this.$socket.send(JSON.stringify(changeRoomMessage));

      // let that = this;
      // let socketMessage = {}
      // socketMessage.type = 2005;
      // if (!this.user.inQueue) {
      //   this.queueConfirmProgress = 60;
      //   this.queueInterval = setInterval((r) => {
      //     let tempDate = new Date().getTime() - that.user.inQueueDate;
      //     that.queueStartTime = this.timestampQueueTime(tempDate / 1000)
      //   }, 1000);
      //   setInterval((r) => {
      //     this.queueWaitTime -= 1 / this.waitTime;
      //   }, 1000)
      // } else {
      //   socketMessage.type = 1002;
      //   clearInterval(this.queueInterval)
      // }
      //this.$socket.send(JSON.stringify(socketMessage));
    },
    initRankScore() {
      if (!this.confirmInitScore) {
        return;
      }
      if (this.user.rankScore != -1) {
        return;
      }
      if (this.user.playTime == 0) {
        Dialog.create({
          title: '警告',
          message: '我们仍无法获取您的Steam数据,这会严重影响你的定位分,你确认定位吗?',
          cancel: true,
          persistent: true
        }).onOk(() => {
          initRank().then((res) => {
            Dialog.create({
              title: '提示',
              message: '你的排位分已初始化,现在开始完成7场定位赛!',
              cancel: false,
              persistent: true
            }).onOk(() => {
              location.reload();
            })
          })
        }).onCancel(() => {
        })
      } else {
        initRank().then((res) => {
          Dialog.create({
            title: '提示',
            message: '你的排位分已初始化,现在开始完成7场定位赛!',
            cancel: false,
            persistent: true
          }).onOk(() => {
            location.reload();
          })
        })
      }
    },
    readyUp() {
      let socketMessage = {}
      socketMessage.type = 1003;
      socketMessage.gameId = this.queueGameId;
      // this.queueConfirmDialog = false;
      // this.queueConfirmProgress = 60;
      clearInterval(this.queueConfirmInterval)
      this.$socket.send(JSON.stringify(socketMessage));

      //避免频繁发送!!!
      this.user.isConfirmReadyUp = true;
    },
    messageHandle(e) {
      if (e.data === "pong") {
        return;
      }
      if (e.data === "ping") {
        //this.$socket.send("pong");
        return;
      }
      let message = JSON.parse(e.data)
      switch (message.type) {
        case 1500:
          this.queueConfirmDialog = false;
          this.queueConfirmProgress = 60;
          clearInterval(this.queueConfirmInterval)
          Notify.create({
            caption: message.msg,
            type: message.msg_type,
            message: '服务器提醒:',
            position: 'top-right',
            progress: true,
            timeout: 5000
          })
          break;
        case 5000:
          this.inQueuePlayer = message.queue_count;
          this.inGamePlayer = message.game_count;
          break;
        case 5001:
          var filterPlayers = [];
          var onlinePlayers = message.online_players;
          onlinePlayers.forEach((o) => {
            if (!o.isGuest) {
              filterPlayers.push(o);
            }
          })
          this.onlinePlayer = filterPlayers;
          break;
        case 5002:
          var messageJson = message.chatList;
          messageJson.forEach((c) => {
            if (c.type !== 0) {
              c.content = JSON.parse(c.content)
            }
          });
          this.chats = messageJson;
          this.scrollHandler();
          break;
        case 5003:
          if (message.chat.persona_name == "System") {
            message.chat.content = JSON.parse(message.chat.content);
          }
          this.chats.push(message.chat)
          this.scrollHandler();
          break;
        case 3000:
          this.user = message.player;
          if (this.user.isRank == 7 && this.user.rankConfirm == 0 && !this.rankModel) {
            this.rankModel = true;
          }
          break;
        case 2001:
          this.queueDialog = false;
          clearInterval(this.queueInterval);
          this.queueGameId = message.game_id;
          this.queueConfirmPlayers = message.confirm_player;
          this.queueConfirmMaps = message.maps;
          this.queueConfirmDialog = true;
          //TODO 避免网络丢包造成的无法确认匹配
          this.user.isConfirmReadyUp = false;
          //TODO 超时未确认匹配
          this.queueConfirmInterval = setInterval((r) => {
            this.queueConfirmProgress -= 1;
            quequeBeep();
            if (this.queueConfirmProgress <= 0) {
              this.queueConfirmDialog = false;
              this.queueConfirmProgress = 60;
              clearInterval(this.queueConfirmInterval)
              this.selectMapId = null
              let socketMessage = {};
              socketMessage.type = 1002;
              this.$socket.send(JSON.stringify(socketMessage));
            }
          }, 1000);
          break;
        case 2002:
          this.queueConfirmPlayers = message.confirm_player;
          break;
        case 2003:
          this.allSelectMapList = message.select_maps;
          break;
        case 2004:
          this.queueConfirmDialog = false;
          this.selectMapId = null
          Notify.create({
            caption: '匹配结束,一部分玩家未准备!请重新匹配',
            type: 'warning',
            message: '失败',
            position: 'center',
            progress: true,
            timeout: 1000 * 15
          })
          break;
        case 2005:
          this.queueWaitTime = 1.0;
          this.waitTime = message.waitTime
          break;
        case 7000:
          //TODO 游戏开始
          stopBeep();
          this.queueConfirmDialog = false;
          this.gameDialog = true;
          this.game.survivorTeam = message.survivor_team;
          this.game.infectedTeam = message.infected_team;
          this.game.gameServer = message.server_info;
          this.game.gameMap = message.map;
          this.game.mixmap = message.mixmap;
          this.game.mixmapValue = message.mixmapValue
          break;
        case 7001:
          //TODO 返回游戏
          this.gameDialog = true;
          this.game.survivorTeam = message.survivor_team;
          this.game.infectedTeam = message.infected_team;
          this.game.gameServer = message.server_info;
          this.game.gameMap = message.map;
          this.game.mixmap = message.mixmap;
          this.game.mixmapValue = message.mixmapValue
          break;
        case 7002:
          this.gameDialog = false;
          // -1 正常结束 -2 游戏初期有玩家未连接 -3 大部分玩家退出游戏 -4 一部分玩家中途退出 -5 游戏途中有玩家被ban -9 服务器与游戏服务器通信失败
          var msg = '';
          var t = "warning"
          switch (message.end_state) {
            case -1:
              if (message.result == 1) {
                msg = "'游戏结束了,你获得比赛胜利!"
              } else if (message.result == -1) {
                msg = "'游戏结束了,你输了比赛!"
              } else {
                msg = "'游戏结束了,游戏平局了!"
              }
              t = "positive"
              break;
            case -2:
              msg = '游戏结束了,游戏初期有玩家未连接';
              break;
            case -3:
              msg = '游戏结束了,大部分玩家退出游戏';
              break;
            case -4:
              msg = '游戏结束了,一部分玩家中途退出';
              break;
            case -5:
              msg = '游戏结束了,游戏途中有玩家被ban';
              break;
            case -9:
              msg = '游戏结束了,服务器与游戏服务器通信失败';
              t = 'negative'
              break;
          }
          Notify.create({
            caption: msg,
            type: t,
            message: '游戏结束',
            position: 'center',
            progress: true,
            timeout: 1000 * 10
          })
          break;
        case 9998:
          //TODO 服务器检查成功
          LoadingBar.start()
          var socketMessage = { type: 9000 }
          this.$socket.send(JSON.stringify(socketMessage));
          socketMessage = { type: 9001 }
          this.$socket.send(JSON.stringify(socketMessage));
          //默认创建一个房间
          socketMessage = { type: 2001 }
          this.$socket.send(JSON.stringify(socketMessage));
          LoadingBar.stop();
          setTimeout((t) => {
            this.initVisiable = false;
          }, 1000)
          break;
        case 6000:
          //被封禁
          var banList = message.bans;
          banList.forEach((ban) => {
            this.banDialog.banBy = ban.banBy;
            this.banDialog.banLength = ban.banLength;
            this.banDialog.remark = ban.remark;
            this.banDialog.createTime = ban.createTime;
            var toTime = null;
            if (ban.banLength == -1) {
              toTime = '永久封禁!如有异议请联系管理!'
            } else {
              toTime = this.timestampToTime((Number.parseInt(ban.createTime) + Number.parseInt(ban.banLength)));
            }
            this.banDialog.toTime = toTime;
            return;
          })
          break;
        //join
        case 5100:
          var joinPlayer = message.player;
          var joinFlag = false;
          this.onlinePlayer.forEach((online) => {
            if (online.steamId === joinPlayer.steamId) {
              joinFlag = true;
            }
          });
          if (!joinFlag && !joinPlayer.isGuest) {
            this.onlinePlayer.push(joinPlayer);
          }
          break;
        //exit
        case 5200:
          var exitPlayer = message.player;
          for (var i = 0; i < this.onlinePlayer.length; i++) {
            if (this.onlinePlayer[i].steamId === exitPlayer.steamId) {
              this.onlinePlayer.splice(i, 1);
              return;
            }
          }
          break;
        //refresh
        case 5300:
          var refreshPlayer = message.player;
          for (var j = 0; j < this.onlinePlayer.length; j++) {
            if (this.onlinePlayer[j].steamId === refreshPlayer.steamId) {
              this.onlinePlayer.splice(j, 1);
              break;
            }
          }
          this.onlinePlayer.push(refreshPlayer);
          break;
        //取消配置文件
        case 2000:
          this.queueConfirmDialog = false;
          this.queueConfirmProgress = 60;
          clearInterval(this.queueConfirmInterval)
          break;
        case 10000:
          this.queueConfirmDialog = false;
          this.queueConfirmProgress = 60;
          clearInterval(this.queueConfirmInterval)
          break;
        /***
         * 组队匹配
         */
        case 3002:
          var that = this;
          this.room = message.room;
          if (this.room.inQueue) {
            this.queueConfirmProgress = 60;
            this.queueInterval = setInterval((r) => {
              let tempDate = new Date().getTime() - that.room.inQueueDate;
              that.queueStartTime = this.timestampQueueTime(tempDate / 1000)
              that.queueWaitTime -= 1 / that.waitTime;
            }, 1000);
            // setInterval((r) => {
            //   this.queueWaitTime -= 1 / this.waitTime;
            // }, 1000)
          } else {
            clearInterval(this.queueInterval)
          }
          break;
        case 3001:
          notifyAudio();
          var roomId = message.roomId;
          var ownerName = message.ownerName;
          var ownerAvatar = message.ownerAvatar;
          Notify.create({
            message: '[' + ownerName + '] 向你发起组队邀请',
            color: 'white',
            textColor: 'black',
            progress: true,
            position: 'top-right',
            avatar: ownerAvatar,
            timeout: 15000,
            actions: [
              {
                label: '接受', color: 'green', handler: () => {
                  let joinRoomMessage = {
                    type: 2003,
                    roomId: roomId,
                  }
                  this.$socket.send(JSON.stringify(joinRoomMessage));
                }
              },
              {
                label: '拒绝', color: 'red', handler: () => { /* ... */
                }
              }
            ]
          })
          break;
      }


    },
    changeAudioVolume() {
      setVolume(this.notifyAudioVolume / 10)
    },
    // selectMap() {
    //   if (this.isSelectMap) {
    //     return;
    //   }
    //   let socketMessage = {}
    //   socketMessage.type = 1004;
    //   socketMessage.gameId = this.queueGameId;
    //   socketMessage.mapId = this.selectMapId;
    //   this.$socket.send(JSON.stringify(socketMessage));
    //   this.isSelectMap = true;
    // },
    invitePlayer(player) {
      let inviteMessage = {
        type: 2002,
        playerId: player.steamId,
      }
      this.$socket.send(JSON.stringify(inviteMessage));
      Notify.create({
        caption: "提示",
        type: "positive",
        message: '向玩家 ' + player.personaName + ' 发送组队邀请',
        position: 'bottom',
        progress: true,
        timeout: 1000 * 3
      })
    },
    quitRoom() {
      let quitRoomMessage = {
        type: 2004,
        roomId: this.room.roomId,
      }

      if (this.room.players.length <= 1) {
        Dialog.create({
          title: '提示',
          message: '你无法退出当前队伍!',
          cancel: false,
          persistent: true
        }).onOk(roomId => {
        })
        return;
      }

      Dialog.create({
        title: '提示',
        message: '你确定退出当前队伍吗?',
        cancel: true,
        persistent: true
      }).onOk(roomId => {
        this.$socket.send(JSON.stringify(quitRoomMessage));
        //退出队伍后自建一个房间
        setTimeout((s) => {
          let createRoomMessage = { type: 2001 }
          this.$socket.send(JSON.stringify(createRoomMessage));
        }, 200)
      })

    },
    sendChatHandle() {
      if ('' === this.sendChatMessage || null === this.sendChatMessage || this.sendChatMessage.trim() === '') {
        return;
      }
      let socketMessage = {}
      socketMessage.type = 7000;
      socketMessage.content = this.sendChatMessage;
      this.$socket.send(JSON.stringify(socketMessage));
      this.sendChatMessage = '';
    },
    scrollHandler() {
      setTimeout((e) => {
        const scrollArea = this.$refs.scrollAreaRef;
        const scrollTarget = scrollArea.getScrollTarget();
        const duration = 100; // ms - use 0 to instant scroll
        scrollArea.setScrollPosition('vertical', scrollTarget.scrollHeight, duration);
      }, 100)
    },
    connectGameServer() {
      window.open("steam://connect/" + this.game.gameServer.serverIp)
    },
    watchGame(server) {
      window.open("steam://connect/" + server.serverIp)
    },
    downloadOtherMap(otherMap) {
      window.open(otherMap.mapDownload)
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    timestampQueueTime(timestamp) {
      var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      return m + s;
    }
  }

}
</script>


<style scoped>
#app {
  /*background-image: linear-gradient(to right, #7d9fbb 0%, rgba(218, 43, 231, 0.66) 100%);*/
  /*background: rgba(241, 241, 241, 0.76);*/
  background-color: #027be3;
}

/*background-image: linear-gradient(to right, #3ba2f8 0%, rgba(43,225,231,0.66) 100%);*/
.bg-color {
  background-color: #027be3;
  /*background-image: linear-gradient(to right, #15ac38 0%, rgba(231, 43, 150, 0.66) 100%);*/
}

.bg-custom-color {
  background-color: #2c2f3b;
}

.method-select {
  transition: all 0.3s;
}

.method-select:hover {
  transform: scale(1.05);
}

.method-select:active {
  transform: scale(0.9);
}

.indexMain {
  height: 80vh;
  /*position: fixed;*/
  /*top: 10;*/
  /*left: 0;*/
  /*width: 100%;*/
  /*height: 100%;*/
  /*overflow: hidden;*/
}
</style>

<style>
.glowing-ready {
  animation: glow 1.5s infinite alternate;
  /* 2s动画，无限循环，来回交替 */
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px rgba(61, 164, 101, 0.8);
    /* 初始阴影效果 */
  }

  to {
    box-shadow: 0 0 30px rgb(61, 164, 101);
    /* 最终阴影效果 */
  }
}
</style>

<style>
html,
body {
  width: 100%;
  height: 100%
}

body.body--dark {
  background: #000
}


.circle-ripple {
  -webkit-animation: ripple .5s linear infinite;
  animation: ripple .5s linear infinite
}


@-webkit-keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(101, 255, 120, 0.3), 0 0 0 1em rgba(101, 255, 120, 0.3), 0 0 0 3em rgba(101, 255, 120, 0.3), 0 0 0 5em rgba(101, 255, 120, 0.3)
  }

  100% {
    box-shadow: 0 0 0 1em rgba(101, 255, 120, 0.3), 0 0 0 3em rgba(101, 255, 120, 0.3), 0 0 0 5em rgba(101, 255, 120, 0.3), 0 0 0 8em rgba(101, 255, 120, 0)
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(101, 255, 120, 0.3), 0 0 0 1em rgba(101, 255, 120, 0.3), 0 0 0 3em rgba(101, 255, 120, 0.3), 0 0 0 5em rgba(101, 255, 120, 0.3)
  }

  100% {
    box-shadow: 0 0 0 1em rgba(101, 255, 120, 0.3), 0 0 0 3em rgba(101, 255, 120, 0.3), 0 0 0 5em rgba(101, 255, 120, 0.3), 0 0 0 8em rgba(101, 255, 120, 0)
  }
}

.blob.queue {
  box-shadow: 0 0 0 0 rgb(51, 153, 217);
  animation: pulse-queue 2.8s infinite;
}

@keyframes pulse-queue {
  0% {
    box-shadow: 0 0 0 0 rgba(51, 167, 217, 0.91)
  }

  30% {
    box-shadow: 0 0 0 40px rgba(51, 217, 178, 0)
  }

  100% {
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0)
  }
}

.blob.green {
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 1.8s infinite
}

@keyframes pulse-green {
  0% {
    box-shadow: 0 0 0 0 rgba(51, 217, 117, 0.91)
  }

  70% {
    box-shadow: 0 0 0 80px rgba(51, 217, 178, 0)
  }

  100% {
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0)
  }
}
</style>

<style>
  .unselectable-text {
    user-select: none;
  }
</style>

<style>
@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.rainbow {
  width: 100%;
  padding: 10px 10px;
  border: 2px solid #ffb700;
}
</style>

<style>
.context {
  z-index: 999;
  width: 100%;
  position: absolute;
}

.context h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}


.area {
  background: #ffffff;
  width: 100%;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 98%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background-image: linear-gradient(120deg, rgba(0, 0, 0, 0.04) 0%, rgba(64, 158, 255, 0.42) 100%);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}


@keyframes animate {

  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }

}
</style>
